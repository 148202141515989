@media(min-width:1024px) {
    html.menu-open, html.modal-open {
        overflow: hidden;
        padding-right: 16px;
    }

    .inner-header-section {
        background-color: #16031F;
    }

    .inner-hero-heading {
        letter-spacing: -8px;
        font-weight: 600;
        font-size: 95px;
        line-height: 100px;
        max-width: 750px;
    }

    .inner-hero-heading.sm {
        letter-spacing: -4px;
        font-size: 75px;
        line-height: 80px;
        max-width: 800px;
    }

    .inner-hero-heading.xl {
        max-width: 1000px;
    }

    .inner-sub-sub-heading {
        font-size: 47px;
        line-height: 52px;
        letter-spacing: -2px;
    }

    .play-button.is-play {
        width: 60px!important;
        height: 60px!important;
    }

    .faq .card .card-header .faq-title {
        padding: 18px 30px 15px 50px;
    }

    ul.faq .collapse {
        padding: 20px 30px 20px 70px;
    }

    .container {
        margin-left: auto;
        margin-right: auto;
    }

    #menu--container {
        width: 30rem;
    }
        
    .main-menu .menu-container-el ul.submenu {
        max-height: 600px;
    }
    
    .textReveal>div p {
        font-size: 30px;
    }
    
    h2.section-head {
        font-size: 2.1875rem;
    }
    
    .about-agency .about-text {
        min-width: 464px;
    }
    
    .pulse-css {
        width: 10px;
        height: 10px;
        border-radius: 10px;
    }
    
    .pulse-css:after, .pulse-css:before {
        width: 10px;
        height: 10px;
        border-radius: 10px;
    }
    
    .locations-map__info-value {
        font-size:100px;
    }
    
    .locations-map {
        width: 580px;
    }
    
    .locations-map__info {
        top: -10px;
    }
    
    .portfolio-pr-el .container-el .el .illu {
        width: 52%;
    }
    
    .portfolio-pr-el .container-el .el:nth-child(odd) .title {
        padding-left: calc(45% + 65px);
    }
    
    .portfolio-pr-el .container-el .el:nth-child(even) .title {
        padding-right: calc(45% + 65px);
        text-align: right;
    }

    .portfolio-pr-el .container-el .el:nth-child(odd) .title h2:before {
        right: calc(100% + 18px);
    }
    
    .portfolio-pr-el .container-el .el:nth-child(even) .title h2:before {
        left: calc(100% + 35px);
    }

    .portfolio-pr-el .container-el .el .title h2:before {
        width: 60px;
    }
    
    .portfolio-pr-el .container-el .el .wrapper .container-img {
        width: 400px;
        height: 272px;
    }
    
    .container-text h2:before {
        width: 60px;
        right: calc(100% + 35px);
    }
    
    .portfolio-pr-el .container-el .el:nth-child(odd) .wrapper .container-text {
        margin-right: -250px;
        margin-left: 80px;
    }
    .portfolio-pr-el .container-el .el:nth-child(even) .wrapper .container-text {
        margin-left: -250px;
        margin-right: 80px;
    }
    
    .portfolio-pr-el .container-el .el:nth-child(even) .wrapper .container-img {
        order: 2;
    }
    
    .Call-Out-Container .call-out-images .image-grid {
        grid-template-columns: 1fr 150px;
    }
    
    .tech-nav-list .nav-link {
        border-bottom: 1px solid #e9e9ec;
        padding: 10px 0 10px 25px;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }
    
    .The-Process {
        border-bottom-left-radius: 30px;
        border-top-left-radius: 30px;
    }
    
    .portfolio-pr-el .container-el .el .title h2 {
        font-size: 36px;
        line-height: 35px;
    }
    
    ul.story-icons-set li {
        flex: 1 1 50%;
        margin-top: 10px;
    }
    
    .modal.modal-side .modal-content {
        padding: 1.5rem 2.5rem;
    }
    
    .modal.modal-side .modal-content button[type="button"] {
        right: 2.5rem;
        top: 2.5rem;
    }
    
    .KgmjGb .A9ExNb {
        height: 208px;
        width: 200px;
    }
    
    .KgmjGb {
        margin: auto;
        max-width: 620px;
    }
    
    .ir-grid {
        grid-template-rows: 350px;
        grid-template-columns: 2fr 1fr;
    }
    
    .ir-grid.reverse {
        grid-template-rows: 350px;
        grid-template-columns: 1fr 2fr;
    }
    
    .ir-grid .image-grid-small {
        height: 70%;
    }
    
    .has-dots {
        width: 170px;
        height: 150px;
    }
    
    .background-shapes__circle-filled {
        height: 378px;
        width: 378px;
    }
    
    .timeline .default-line {
            width: 50px;
    }
    
    .timeline .draw-line {
        width: 50px;
    }
    
    .easy-steps-header {
        border-bottom-left-radius: 100px;
        border-bottom-right-radius: 100px;
    }
    
    .easy-steps-header .easy-steps-inner {
        padding: 9.5rem 6rem;
    }
    
    .circle_wrapper {
        top: -25px;
        left: 80px;
    }
    
    .steps-circle {
        width: 50px;
        height: 50px;
    }
    
    .post-wrapper .post {
        padding: 10px 0px 10px 0px;
    }
    
    .post-wrapper .post.right {
        margin-right:90px;
    }
    
    .post-wrapper .post.left {
        margin-left:90px;
    }
    
    .post-wrapper .post .post-content .post-title {
            font-size: 30px;
            line-height: 42px;
        }
    
    .timeline ul li::before {
        left:50%;
        transform: translateX(-50%);
    }
    
    .timeline ul li.in-view::before {
        left: 50%;
        transform: translateX(-50%);
        width: 42px;
        height: 42px;
        }
    
    .timeline .default-line, .timeline .draw-line {
        left: 50%;
        transform: translateX(-50%);
    }
    
    .timeline ul li {
        margin: 0 auto;
        height: 280px;
        width: 50px;
        margin-bottom: 20px;
        top: 5rem;
    }
    
    .wz-splitz .maze-left {
        width: calc(60% - (35px / 2));
    }
    
    .wz-splitz .maze-right {
        width: calc(40% - (35px / 2));
    }
    
    .banner__background {
        min-height: 950px;
    }
    
    /* .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    } */
    
    .service-card-line {
        height: 100%;
    }
    
    .fly-menu .fly-menu-inner .menu-wrapper {
        width: clamp(1080px,80vw,1800px);
    }
    
    .text-editor p:nth-child(1) {
        font-size: larger;
    }
    
        @media screen and (min-width: 1199px) {
        
            .fly-menu .fly-menu-inner .menu-wrapper .container-nav li a .link-line::before {
                transform: scaleX(.5);
            }
            
            .fly-menu .fly-menu-inner .menu-wrapper .container-nav li a:hover .link-line::before {
                transform: scaleX(1);
            }
            
            .fly-menu .fly-menu-inner .menu-wrapper .container-nav li a:not(.social) .word-wrap .word-wrap-inner {
                line-height: 60px;
            }
            
            .fly-menu .fly-menu-inner .menu-wrapper .container-nav li a .link-circle {
                opacity: 0;
                z-index: -1;
                transition: opacity 0.2s ease-in-out;
                background-color: rgba(0,0,0,0.07);
            }
            
            .fly-menu .fly-menu-inner .menu-wrapper .container-nav li a:hover .link-circle {
                opacity: 1;
            }
            
            /* .fly-menu .fly-menu-inner .menu-wrapper .service-reveal {
                opacity: 0;
                visibility: hidden;
                transform: translateY(20px);
            } */
            
            /* .el-solutions .fly-menu .fly-menu-inner .menu-wrapper .service-reveal {
                opacity: 1;
                visibility: visible;
                transform: none;
                transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                transition-delay: 0.2s;
            } */
    
        }
}